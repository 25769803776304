// searchButtonMove.js
export function moveSearchButton() {
  const menu = document.querySelector('.wp-block-navigation__responsive-container-content');
  const searchButton = document.querySelector('.header-search');


  if (window.innerWidth <= 880 ) {
    if (menu && searchButton) {
      menu.appendChild(searchButton);
    }
  }
}
