import { moveSearchButton } from './components/moveSearchButton';
import { initializeTOC } from './components/tocFunctions';


window.addEventListener('load', moveSearchButton);
window.addEventListener('resize', moveSearchButton);


document.addEventListener("DOMContentLoaded", function () {
    initializeTOC();

});


jQuery(document).ready(function ($) {


    if ($('.search-results .wp-block-post').length > 0) {
    $('.search-results .wp-block-post').on('click', function() {
        var href = $(this).find('a').attr('href');
        if (href) {
            window.location.href = href;
        }
    });
    }

// croll to open-vacancies karriere
    var button = document.querySelector('.stelle-button');
    var target = document.getElementById('open-vacancies');

    if (button && target) {
        button.addEventListener('click', function(event) {
            event.preventDefault();
            window.scrollTo({
                top: target.offsetTop,
                behavior: 'smooth'
            });
        });
    }



 //   move.referenz-top-img-column in single referenz
    function moveElement() {
        // Select the element to be moved
        var elementToMove = $('.referenz-single-wrap2 .referenz-top-img-column');

        // Select the target element where it will be moved to
        var targetElement = $('.referenz-top-img-column.toc-column .scroll-column-right');

        // Check the window width
        if ($(window).width() > 1025) {
            // Move the element to the target location
            elementToMove.detach().appendTo(targetElement);
        }
    }

    // Initial check
    moveElement();

    // Re-check on window resize
    $(window).resize(function () {
        moveElement();
    });



    $('.slick-slider').on('afterChange', function(event, slick, currentSlide) {
        var video = $('.wp-block-video video')[0];
        // При смене слайда
        $(".wp-block-video video").each(function() {
            // this.pause(); // Поставить все видео на паузу
            video.muted = true;
        });


    });

    $('.play-btn-js').on('click', function() {
        var video = $('.wp-block-video video')[0];
        if (video.muted) {
            video.muted = false;
            $(this).removeClass('muted');
        } else {
            video.muted = true;
            $(this).addClass('muted');
        }
    });


    // Select all .referenz-detail-item elements
    var referenzDetailItems = $('.referenz-detail-item');



    referenzDetailItems.each(function() {
        var referenzDetailItem = $(this);
        var strongElement = referenzDetailItem.find('strong');

        if ($.trim(strongElement.html()) === '') {
            referenzDetailItem.hide();
        }
    });

    function moveBlocksOnResize() {
            var windowWidth = $(window).width();


            if (windowWidth > 1025) {

                var blockToMove = $('.referenz-single-wrap2 .scroll-column-right .wp-block-column');
                var destinationBlock = $('.referenz-single-wrap .scroll-column-right');

                if (blockToMove.length > 0 && destinationBlock.length > 0) {

                    blockToMove.detach();
                    destinationBlock.append(blockToMove);
                }
            }
        }

        moveBlocksOnResize();

        $(window).resize(function() {
            moveBlocksOnResize();
        });




    // refernez column padding

    function setPaddingRight() {
    var columnsBlocks =   $('.single-referenzen  header ').outerWidth() - $('.single-referenzen  header .wp-block-columns').outerWidth() ;
    var paddingLeftValue = columnsBlocks / 2;
    $('.single-referenzen .referenz-top-img-column').css('padding-right', paddingLeftValue + 'px');

    }

    var windowWidth = $(window).width();
    if (windowWidth > 1002) {
    setPaddingRight();
    // Call the function again when window is resized
    $(window).resize(function() {
        setPaddingRight();
    });
    }


    function moveBreadcrumb() {
        if ($(window).width() < 781) {
            $('.single-vacancy-top-img').prepend($('.bateg-breadcrumb'));
        } else {
            $('#breadcrumb-container').append($('.bateg-breadcrumb'));
        }
    }

    moveBreadcrumb();
    $(window).resize(moveBreadcrumb);



    /*
    search in header
     */
    $(".header-search").click(function (e) {
        e.stopPropagation();

        if ($(window).width() > 879) {
            var searchForm = $(".wp-block-search").detach();
            $("#nav-search-js").append(searchForm);
            $('#nav-search-js .wp-block-search').show();
            $('body').addClass('active-search');

            // Установка фокуса с задержкой
            setTimeout(function() {
                searchForm.find('.wp-block-search__input').focus();
            }, 100); // Измените значение задержки по необходимости
        } else {
            $(".wp-block-search").show();
            $(".wp-block-search .wp-block-search__input").focus();
            $('body').addClass('active-search');
        }
    });

    $(document).click(function (e) {
        if (!$(e.target).closest('header').length) {
            $('body').removeClass('active-search');
            if ($(window).width() > 879) {
                $('#nav-search-js .wp-block-search').hide();
            }else{
                $('.bateg-header .wp-block-search').hide();
            }
        }
    });



  /*
    custom navigation for main slider
   */

    var slider = $('.bateg-main-slider');

    // Функция для создания навигации и привязки обработчиков событий
    function createNavigation() {
        // Создаем разметку для навигации
        var numSlides = slider.find('.slick-slide').length;
        var navHTML = '<div class="bateg-custom-navigation">';

        for (var i = 0; i < numSlides; i++) {
            navHTML += '<span class="dot" data-index="' + i + '"></span>';
        }

        navHTML += '</div>';


        $('.bateg-slider-text > .wp-block-group').prepend(navHTML);


        $('.bateg-custom-navigation .dot:first').addClass('active');


        slider.on('afterChange', function(event, slick, currentSlide) {
            var dots = $('.bateg-custom-navigation .dot');
            dots.removeClass('active');
            dots.filter('[data-index="' + currentSlide + '"]').addClass('active');
        });


        $(document).on('click', '.bateg-custom-navigation .dot', function() {
            var index = $(this).data('index');
            slider.slick('slickGoTo', index);
        });
    }


    function checkWindowWidth() {
        var existingNavigation = $('.bateg-custom-navigation')


        if (window.matchMedia('(max-width: 1031px)').matches) {
            if (existingNavigation.length === 0) {
                createNavigation();
            }
        } else {

            $('.bateg-custom-navigation').remove();
        }
    }


    checkWindowWidth();


    $(window).on('resize', checkWindowWidth);


    $('.bateg-custom-navigation .dot:first').addClass('active');
});









// dropdown

document.addEventListener('DOMContentLoaded', function() {
    // Добавляем обработчик события клика для всего документа
    document.addEventListener('click', function(event) {
        // Проверяем, был ли клик на кнопке выпадающего меню
        var dropdownToggle = event.target.closest('.dropdown-toggle');
        if (dropdownToggle) {
            var dropdownMenu = dropdownToggle.nextElementSibling;
            dropdownMenu.classList.toggle('show');
        } else {
            // Если клик был вне любого выпадающего меню, скрываем все меню
            var dropdownMenus = document.querySelectorAll('.dropdown-menu');
            dropdownMenus.forEach(function(dropdownMenu) {
                if (!dropdownMenu.contains(event.target)) {
                    dropdownMenu.classList.remove('show');
                }
            });
        }
    });


    var dropdownMenus = document.querySelectorAll('.dropdown-menu');
    dropdownMenus.forEach(function(dropdownMenu) {
        var dropdownItems = dropdownMenu.querySelectorAll('.dropdown-item');
        dropdownItems.forEach(function(item) {
            item.addEventListener('click', function() {
                var selectedItemText = item.textContent;
                var dropdownToggle = dropdownMenu.previousElementSibling;
                dropdownToggle.textContent = selectedItemText;
                dropdownMenu.classList.remove('show');
            });
        });
    });
});








