// tocFunctions.js
export function initializeTOC() {
    // // Cache the navigation links
    //     const navigationLinks = Array.from(document.querySelectorAll('#toc-list li a'));
    //
    // // Cache (in reversed order) the sections
    //     const sections = Array.from(document.querySelectorAll("#toc-content h3")).reverse();
    //
    // // Map each section id to their corresponding navigation link
    //     const sectionIdToNavigationLink = {};
    //     sections.forEach(section => {
    //         const id = section.getAttribute('id');
    //
    //         sectionIdToNavigationLink[id] = document.querySelector(`#toc-list  li  a[href=\\#${id}]`);
    //     });

    // page header
    var scrolling = false;
    const pageHeader = document.querySelector('.bateg-header');
    const tocCover = document.querySelector('#toc-cover');
    const tocList = document.getElementById('toc-list');
    const contentHeadings = document.querySelectorAll(".toc-list-item");
    const progressBar = document.querySelector('.toc-nav .progress-bar-wrap');
    const replaceLastSpecialChars = str => {
        const regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (regex.test(str[str.length - 1])) {
            return str.slice(0, -1);
        }
        return str;
    };
    const createTOC = function () {
        contentHeadings.forEach(function (heading, index) {
            const headingText = heading.innerText;
            const listItem = document.createElement('li');
            const anchor = document.createElement('a');
            const circleSpan = document.createElement('span');
            circleSpan.classList.add('progress-bar--circle');
            if (index !== contentHeadings.length - 1) {
                listItem.appendChild(index !== contentHeadings.length - 2 ? progressBar?.cloneNode(true) : progressBar);
            }

            // Create a more readable anchor based on the heading text
            const anchorText = headingText.toLowerCase().replace(/\s+/g, '').replace(/\d+\./, '');
            anchor.href = `#${anchorText}`;
            anchor.textContent = replaceLastSpecialChars(headingText.replace(/^\d\./, ''));
            anchor.appendChild(circleSpan);

            // Create a span for the ordinal number
            const numberSpan = document.createElement('span');
            numberSpan.textContent = index + 1 + '.';
            numberSpan.classList.add('toc-number');
            // Append the ordinal number to the li element
            listItem.appendChild(numberSpan);

            // Append the anchor to the li element
            listItem.appendChild(anchor);
            tocList.appendChild(listItem);

            // Add an id to the content headings
            heading.id = anchorText;
        });
    };
    if (tocList) {
        const tocWrapper = document.querySelector('.toc-group');

        // Function for smooth scrolling
        const smoothScrolling = function () {
            const isMobileView = window.innerWidth <= 1025;
            document.querySelectorAll('a[href^="#"]').forEach(anchor => {
                anchor.addEventListener('click', function (e) {
                    e.preventDefault();
                    const targetId = this.getAttribute('href').substring(1);
                    const targetElement = document.getElementById(targetId);
                    if (targetElement) {
                        let targetOffset = targetElement.offsetTop - pageHeader.clientHeight;
                        if (anchor.parentElement.parentElement.id === 'toc-list') {
                            targetOffset = tocWrapper.offsetTop + targetElement.offsetTop - (isMobileView ? tocCover.clientHeight + pageHeader.clientHeight : pageHeader.clientHeight);
                        }
                        if (!scrolling) {
                            window.scrollTo({
                                top: targetOffset,
                                behavior: 'smooth'
                            });
                        }
                    }
                });
            });

            // Получение ссылки на список
            let tocList = document.getElementById('toc-list');

            // Получение всех элементов списка
            let listItems = tocList.getElementsByTagName('li');

            // Добавление обработчика события касания к каждому элементу списка
            for (let i = 0; i < listItems.length; i++) {
                listItems[i].addEventListener('touchstart', function (event) {
                    // Получение текста элемента, на который произошло касание
                    let listItemText = this.textContent;
                    // Добавление вашей логики обработки касания на элемент списка
                    // console.log('Вы коснулись элемента: ' + listItemText);
                    const anchor = this.querySelector("a");
                    const targetId = anchor.getAttribute('href').substring(1);
                    const targetElement = document.getElementById(targetId);
                    //console.log('11111111',targetElement);

                    if (targetElement) {
                        let targetOffset = targetElement.offsetTop - pageHeader.clientHeight;
                        if (anchor.parentElement.parentElement.id === 'toc-list') {

                            targetOffset = tocWrapper.offsetTop + targetElement.offsetTop - (isMobileView ? tocCover.clientHeight + pageHeader.clientHeight : pageHeader.clientHeight);
                        }
                        if (!scrolling) {
                            window.scrollTo({
                                top: targetOffset,
                                behavior: 'smooth'
                            });
                        }
                    }
                });
            }
        };
        const scrollIntoView = elem => {
            //   if (!scrolling) {
            //alert("Next elem");
            tocList.scrollTo({

                left: elem.parentElement.offsetLeft,
                behavior: 'smooth'
            });
            // }
        };
        const scrollIntoViewMob = elem => {

            //   if (!scrolling) {
            //alert("Next elem");
            tocList.scrollLeft = elem.parentElement.offsetLeft;
            // }
        };
        window.addEventListener('scroll', function () {
            scrolling = true; // устанавливаем флаг, что прокрутка идет от пользователя
        });
        function smoothScrollTo(targetOffset) {
            if (!scrolling) { // проверяем, если прокрутка не идет от пользователя
                window.scrollTo({
                    top: targetOffset,
                    behavior: 'smooth',
                });
            }
        }

        const highlightNavigation = function () {

            const winPos = window.scrollY;
            const isMobileView = window.innerWidth <= 1025;

            // Scrollbar progression
            const tocOffsetTop = tocWrapper.offsetTop;
            const mainContent = document.getElementById('toc-content');
            // console.log('mainContent', mainContent);
            // Loop through articles and highlight corresponding navigation links
            const articles = mainContent.querySelectorAll('.toc-list-item');

            articles.forEach(function (article, index) {
                const articleTop = tocOffsetTop + article.offsetTop - (isMobileView ? tocCover.clientHeight + pageHeader.clientHeight + 15 : pageHeader.clientHeight);
                const nextArticle = articles[index + 1];

                //  console.log('77777777',index,tocList.querySelectorAll('.progress-bar--increment'))
                const progressBarIncrement = tocList.querySelectorAll('.progress-bar--increment')[index];
                const navLink = tocList.querySelectorAll('a')[index];
                const progressCircle = tocList.querySelectorAll('.progress-bar--circle')[index];
                if (nextArticle && winPos >= articleTop) {
                    const articleHeight = nextArticle.offsetTop - article.offsetTop;
                    const scrollPercent = (winPos - articleTop) / articleHeight;
                    const scrollPercentRounded = Math.round(scrollPercent * 100);
                    // console.log('scrollPercentRounded', scrollPercentRounded);
                    //alert("next art");
                    if (isMobileView) {
                        const widthValue = scrollPercentRounded < 100 ? scrollPercentRounded : 100;
                        if (progressBarIncrement) progressBarIncrement.style.width = `${widthValue}%`;
                    } else {
                        const heightValue = scrollPercentRounded < 100 ? scrollPercentRounded : 100;
                        if (progressBarIncrement) progressBarIncrement.style.height = `${heightValue}%`;
                    }
                }
                if (winPos >= articleTop) {
                    // Add 'active' class to the corresponding navigation link
                    if (nextArticle) {
                        //alert("Next article");
                        const nextArticleTop = tocOffsetTop + nextArticle.offsetTop - (isMobileView ? tocCover.clientHeight + pageHeader.clientHeight + 15 : pageHeader.clientHeight);
                        if (winPos < nextArticleTop) {
                            navLink.classList.add('active');
                            navLink.closest('li').classList.add('active');
                            isMobileView && scrollIntoViewMob(navLink);
                            //   navLink.classList.remove('active');
                        } else {
                            navLink.classList.remove('active');
                            navLink.closest('li').classList.remove('active');

                        }
                    } else {
                        navLink.classList.add('active');
                        navLink.closest('li').classList.add('active');
                        isMobileView && scrollIntoView(navLink);
                    }

                    // Add 'active' class to the corresponding circle element
                    progressCircle.classList.add('active');
                } else {
                    navLink.classList.remove('active');
                    navLink.closest('li').classList.remove('active');
                    progressCircle.classList.remove('active');
                    if (isMobileView) {
                        if (progressBarIncrement) progressBarIncrement.style.width = '0';
                    } else {
                        if (progressBarIncrement) progressBarIncrement.style.height = '0';
                    }
                }
            });
        };
        const winEvents = ['load', 'scroll', 'resize'];

        // Initial function calls
        createTOC();

        window.addEventListener('load', smoothScrolling);
        // handleTOCScroll();
        // window.addEventListener('scroll', handleScrollIndicator);
        winEvents.forEach(event => {
            window.addEventListener(event, highlightNavigation);
        });
    }
}
